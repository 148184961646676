import Vue from 'vue';
import App from './App.vue';
import { router } from './router';
import MintUI from 'mint-ui';
import moment from 'moment';
import 'mint-ui/lib/style.css';
import store from './store/store.js';
import 'lib-flexible';
// import i18n from './lang';

// import ElementUI from 'element-ui';
import {
  Button,
  Pagination,
  DatePicker,
  Dropdown,
  DropdownMenu,
  DropdownItem,
  Table,
  TableColumn,
  Input,
  Progress
} from 'element-ui';
// 国际化
const messages = {
  ZH: zhContent,
  JA: jaContent,
  KO: koContent,
  EN: enContent
};

import { zhContent } from './assets/js/zh';
import { jaContent } from './assets/js/ja';
import { koContent } from './assets/js/ko';
import { enContent } from './assets/js/en';

import VueI18n from 'vue-i18n';

import './assets/scss/common.css';
const i18n = new VueI18n({
  locale: 'EN', // 语言标识
  messages
});

Vue.use(Button);
Vue.use(Pagination);
Vue.use(DatePicker);
Vue.use(Dropdown);
Vue.use(Table);
Vue.use(TableColumn);
Vue.use(Input);
Vue.use(DropdownMenu);
Vue.use(DropdownItem);
Vue.use(Progress);
import 'element-ui/lib/theme-chalk/index.css';

import './assets/font/iconfont.css';

import Vant from 'vant';
import 'vant/lib/index.css';

Vue.use(Vant);

Vue.config.productionTip = false;
Vue.use(MintUI);
Vue.prototype.$moment = moment;
new Vue({
  i18n,
  router,
  store,
  render: h => h(App)
}).$mount('#app');
