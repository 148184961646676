const zhContent = {
  '行情热搜': '行情热搜',
  '交易平台': '交易平台',
  '没有更多了': '没有更多了',
  '资讯公告': '资讯公告',
  '全球总市值': "全球总市值",
  'BTC比特币': 'BTC比特币',
  'ETH以太坊': 'ETH以太坊',
  '资讯公告': '资讯公告',
  '热搜榜': '热搜榜',
  '市值榜': '市值榜',
  '灰度基金': '灰度基金',
  '币种': '币种',
  '持币数量/价值': '持币数量/价值',
  '1天变化/7天变化': '1天变化/7天变化',
  '合约榜': '合约榜',
  '2020减半': 'DeFi' || '2020减半',
  '名称': '名称',
  '热度': '热度',
  '流通市值': '流通市值',
  '法币价格': '法币价格',
  '今日涨幅': '今日涨幅',
  '换手率': '换手率',
  '币种/流通市值': '币种/流通市值',
  '全球指数': '全球指数',
  '24h成交额': '24h成交额',
  '24h成交额': '24h成交额',
  '持有':'持有',
  '24h涨幅': '24h涨幅',
  '24H涨幅': '24H涨幅',
  '24H额': '24H额',
  '24H量': '24H量',
  '24H低': '24H低',
  '24H高': '24H高',
  '24h成交量': '24h成交量',
  '流动性': '流动性',
  '上线时间': '上线时间',
  '持有资金量': '持有资金量',
  '交易对': '交易对',
  '交易所': '交易所',
  '资讯公告': '资讯公告',
  '查看更多': '查看更多',
  '全部资讯': '全部资讯',
  '交易行情': '交易行情',
  '交易k线': '交易k线',
  '基本信息': '基本信息',
  '历史数据': '历史数据',
  '直达': '直达',
  '占比': '占比',
  '更新时间': '更新时间',
  '全网': '全网',
  '流通率': '流通率',
  '全球总市值占比': '全球总市值占比',
  '历史数据': '历史数据',
  '日期': '日期',
  '开盘价': '开盘价',
  '最高价': '最高价',
  '最低价': '最低价',
  '收盘价': '收盘价',
  '市值': '市值',
  '开始日期': '开始日期',
  '结束日期': '结束日期',
  '发行时间': '发行时间',
  '最大供应量': '最大供应量',
  '总供应量': '总供应量',
  '上架交易所': '上架交易所',
  '核心算法': '核心算法',
  '奖励机制': '奖励机制',
  '概念分类': '概念分类',
  '相关链接': '相关链接',
  '官网地址': '官网地址',
  '白皮书': '白皮书',
  '区块站': '区块站',
  '社交网站': '社交网站',
  '发展历程和路线': '发展历程和路线',
  '简介': '简介',
  '搜索资讯': '搜索资讯',
  '全部资讯': '全部资讯',
  '访问量': '访问量',
  '全球综合排名榜': '全球综合排名榜',
  '24H涨跌幅': '24H涨跌幅',
  '成立时间': '成立时间',
  '资产实力': '资产实力',
  '人气指数': '人气指数',
  '注册地区': '注册地区',
  '币种数量': '币种数量',
  'KYC认证': 'KYC认证',
  '应用下载': '应用下载',
  '手续费用': '手续费用',
  '交易支持': '交易支持',
  '阶段涨幅': '阶段涨幅',
  '最新价': '最新价',
  '平台价': '平台价',
  '价格': '价格',
  '币种搜索':'币种搜索',
  'nav': {
    '1日': '1日',
    '1周': '1周',
    '1月': '1月',
    '6月': '6月',
    '1年': '1年',
    '3年': '3年',
    '全部': '全部',
    '今日': '今日',
    '近7天': '近七天',
    '近30天': '近30天',
    '近3个月': '近3个月',
    '近6个月': '近6个月',
    '近一年': '近一年',
    '所有': '所有',
    '过去7天': '过去7天',
    '过去30天': '过去30天',
    '过去12个月': '过去12个月'
  },
   '搜索你想要的货币资讯': '搜索你想要的货币资讯',
   '搜索你想要的交易所信息': '搜索你想要的交易所信息',
   '24H成交量': '24H成交量',
   '24H成交额': '24H成交额',
   '暂无数据': '暂无数据',
   '市场行情': '市场行情',
   '行情': '行情',
   '市值总额': '市值总额',
   '发行日期': '发行日期',
   '时间': '时间',
   '官网备用地址': '官网备用地址',
   '是':'是',
   '否':'否'
}
export {
  zhContent
}
