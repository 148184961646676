const jaContent = {
  '行情热搜': '通貨指標',
  '没有更多了': 'これ以上ないです',
  '交易平台': '取引所',
  '资讯公告': 'ツイッター',
  '全球总市值': "グローバル時価総額",
  'BTC比特币': 'BTC比特币',
  'ETH以太坊': 'ETH以太坊',
  '资讯': 'ツイッター',
  '快讯': 'ニュース',
  '热搜榜': '人気検索ランキング',
  '市值榜': '時価総額ランキング',
  '灰度基金': 'グレイスケール基金',
  '币种': '通貨',
  '持币数量/价值': '保有数/値',
  '1天变化/7天变化': '1日変更/ 7日変更',
  '合约榜': '契約チャート',
  '2020减半': 'DeFi' || '2020半減',
  '名称': '通貨名称',
  '热度': 'ぬるい',
  '流通市值': '流通量時価総額',
  '法币价格': '法定通貨価格',
  '法币价格': '法定通貨価格',
  '今日涨幅': '前日比降順',
  '换手率': '回転率',
  '24h成交额': '24h売買代金',
  '24h涨幅': '24h',
  '24H涨幅': '24h',
  '币种/流通市值': '通貨/流通市場価値',
  '全球指数': 'グローバル指数',
  '热度': 'ぬるい',
  '24H额': '24h額',
  '24H成交额': '24H 成約額',
  '持有':'持っています',
  '24H量': '24h量',
  '24H低': '24hが低い',
  '24H高': '24h高',
  '币种':'貨幣種類',
  '平台':'プラットフォーム',
  '最低价':'最低価',
  '24h成交量': '24h出来高',
  '持有资金量': '持有资金量',
  '流动性': '流動性',
  '上线时间': 'オンライン時間',
  '交易对': '対応通貨ペア',
  '交易所': '取引所',
  '资讯': 'ツイッター',
  '快讯': 'ニュース',
  '查看更多': 'もっと調べて',
  '全部资讯': '全ての情報',
  '交易行情': '市場動向',
  '交易k线': 'トレーディングKライン',
  '基本信息': '基本情報',
  '历史数据': '履歴データ',
  '直达': '直通',
  '占比': '割合を占める',
  '更新时间': '更新時間',
  '全网': '全网',
  '流通率': '循環率',
  '全球总市值占比': 'グローバル時価総額の割合',
  '历史数据': '履歴データ',
  '日期': '日期',
  '开盘价': '始め値',
  '最高价': '最高値',
  '最低价': '最低価',
  '收盘价': 'ひけね',
  '市值': '時価',
  '开始日期': '開始日時',
  '结束日期': '終了期日',
  '发行时间': '発行時期',
  '最大供应量': '最大量',
  '总供应量': '総供給量',
  '上架交易所': '上場した取引所の数',
  '核心算法': 'コアアルゴリズム',
  '奖励机制': 'コンセンサスメカニズム',
  '概念分类': '概念分類',
  '相关链接': 'リンク',
  '官网地址': '公式サイト',
  '白皮书': '白書',
  '区块站': 'ブロックチェーンブラウザ',
  '社交网站': 'ソーシャルサイト',
  '发展历程和路线': '発展の歴史と路線',
  '简介': '詳細',
  '搜索资讯': '情報検索',
  '全部资讯': '全ての情報',
  '访问量': 'ページビュー',
  '全球综合排名榜': '総合ランキング',
  '24H涨跌幅': '24H上昇幅',
  '成立时间': '成立時期',
  '资产实力': '資産実力',
  '人气指数': '人気指数',
  '注册地区': '登録の場所',
  '币种数量': '貨幣の種類の数',
  'KYC认证': 'KYC 認証',
  '应用下载': 'ダウンロードを適用',
  '手续费用': '手数料',
  '交易支持': '取引サポート',
  '阶段涨幅': 'ステージアップ',
  '价格': '価格',
  '最新价': '最新価格',
  '平台价': 'プラットフォーム価格',
  '币种搜索':'通貨検索',
  'nav': {
    '1日': '1日',
    '1周': '1週間',
    '1月': '1か月',
    '6月': '6か月',
    '1年': '1年',
    '3年': '3年',
    '全部': '全部',
    '今日': '今日',
    '近7天': '7日近く',
    '近30天': '30日近く',
    '近3个月': '約3ヶ月',
    '近6个月': '約6ヶ月',
    '近一年': 'ほぼ一年',
    '所有': '全部',
    '过去7天': '過去7日間',
    '过去30天': '過去30日間',
    '过去12个月': '過去12ヶ月'
  },
   '搜索你想要的货币资讯': '情報検索',
   '搜索你想要的交易所信息': '情報を検索します',
   '24H成交量': '24h出来高',
   '24H成交额': '24h売買代金',
   '暂无数据': 'データがありません',
   '市场行情': '市況',
   '行情': '市況',
   '市值总额': '時価総額',
   '发行日期': '発行日',
   '时间': '時間',
   '官网备用地址': '公式サイトの予備アドレス',
   '是':'はい、',
   '否':'いいえ、'
}
export {
  jaContent
}
