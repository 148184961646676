import axios from '../utils/request';

// 头部市值榜
const marketvalue = param => {
  return axios.get('v1/btcbay/marketcap', {
    params: param
  });
};

// 获取交易对市场行情法币编号信息列表
const quoteCurrencies = () => {
  return axios.get('v1/btcbay/common/quoteCurrencies', {});
};

//  获取市值榜信息列表
const marketcurrencies = param => {
  return axios.get('v1/app/btcbay/currencies/market', {
    params: param
  });
};
//获取热搜榜
const hotSearch = param => {
  return axios.get('/v1/app/btcbay/currencies/hot', {
    params: param
  });
};
// 获取灰度基金
const grayFund = param => {
  return axios.get('/v1/gray', {
    params: param
  });
};
// 获取meta
const getMeta = param => {
  return axios.get('/v1/btcbay/currencies/meta', {
    params: param
  })
}

// 货币基本信息
const coinDeil = function(coinId) {
  return axios.get(`v1/btcbay/currencies/${coinId}/detail`);
};

// 货币24h行情
const marketDetail = function(param) {
  return axios.get(
    `v1/btcbay/currencies/${param.currencyId}/24h?language=${param.language}`
  );
};

// 货币交易详情
const coinQuotation = function(coinId) {
  return axios.get(`v1/btcbay/currencies/${coinId}/quotation`);
};

// 市场行情
const marketCondition = function(param) {
  return axios.get(`v1/btcbay/markets`, {
    params: param
  });
};

// 获取交易平台详情
const exchangeDetail = function(exchangeId) {
  return axios.get(`v1/btcbay/exchanges/${exchangeId}/detail`);
};

// 历史数据
const hisotoryData = function(currentId, param) {
  return axios.get(`v1/btcbay/currencies/${currentId}/historical`, {
    params: param
  });
};
// 汇率换算
const conversion = function() {
  return axios.get(`v1/btcbay/common/price/conversion`);
};

// 交易平台信息列表
const getConfigs = () => {
  return axios.get('v1/btcbay/common/configs');
};
// 交易平台关于我们信息
const getSetting = () => {
  return axios.get('/v1/app/setting');
};

//
//Defi数据
const gethalving = function(param) {
  return axios.get(`v1/app/btcbay/currencies/halving`, {
    params: param
  });
};

//搜索
const getsearchVal = function(param) {
  return axios.get(`/v1/app/btcbay/currencies`, {
    params: param
  });
};

//
const getexchanges = function(param) {
  return axios.get(`/v1/app/btcbay/exchanges`, {
    params: param
  });
};

//交易平台
const getexchangesList = function(param) {
  return axios.get(`/v1/btcbay/exchanges`, {
    params: param
  });
};

//咨询公告
//
const getnews = function(param) {
  return axios.get(`/v1/app/btcbay/news/flash`, {
    params: param
  });
};

//

export {
  coinDeil,
  marketDetail,
  coinQuotation,
  exchangeDetail,
  hisotoryData,
  conversion,
  marketCondition,
  marketvalue,
  quoteCurrencies,
  marketcurrencies,
  hotSearch,
  getConfigs,
  getSetting,
  gethalving,
  getsearchVal,
  getexchanges,
  getexchangesList,
  getnews,
  grayFund,
  getMeta
};
