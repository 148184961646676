import Vue from 'vue';
import Router from 'vue-router';
import i18n from './lang';
import { conversion } from './api/index';
Vue.use(Router);
import { Indicator } from 'mint-ui';
const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import('./view/market/market'),
    meta: {},
    children: [
      {
        path: '/',
        redirect: 'market'
      },
      {
        path: '/market',
        component: () => import('./view/market/market'),
        meta: {
        }
      },
    ]
  },
  {
    path: '/exchange',
    component: () => import('./view/market/tradingplatform'),
    meta: {},
  },
  {
    path: '/exchange/exchange-detail',
    component: () => import('./view/exchange-detail/detail.vue'),
    meta: {}
  },
  {
    path: '/information',
    component: () => import('./view/market/information'),
    meta: {}
  },
  {
    path: '/search',
    component: () => import('./view/market/search'),
    meta: {}
  },
  {
    path: '/languageChange',
    component: () => import('./view/market/language'),
    meta: {}
  },
  {
    path: '/type',
    component: () => import('./view/market/type'),
    meta: {}
  },
  {
    path: '/typenav',
    component: () => import('./view/market/typenav'),
    meta: {}
  },

  // 

  {
    path: '/coin-detail',
    component: () => import('./view/coin-detail/detail'),
    meta: {}
  },
  {
    path: '/download',
    component: () => import('./view/download.vue'),
    meta: {}
  }
];

// add route path
const router = new Router({
  mode: 'hash',
  routes
});
router.beforeEach((to, from, next) => {
  const title = to.meta && to.meta.title;
  if (title) {
    document.title = title;
  }
  i18n.locale = to.query.language;
  Indicator.open('加载中...');
  conversion().then(res => {
    Indicator.close();
    if (res.resultStatus) {
      localStorage.setItem('priceConversion', JSON.stringify(res.resultData));
      next();
    }
  });
});

export { router };
