import Vue from 'vue'
import Vuex from 'vuex'
Vue.use(Vuex);

const state = {
    quoteCurrencyCode: '',
    priceConversion: '',
    quotationFlag: 1,
    searchKeyWord:''
}

const getters = {
    getQuoteCurrencyCode(state) {
        return state.quoteCurrencyCode
    },
    getPriceConversion(state) {
        return state.priceConversion;
    },
    getQuotationFlag(state) {
        return state.quotationFlag;
    },
    getSearchKeyWord(state){
        return state.searchKeyWord
    }
}
const mutations = {
    updateQuoteCurrencyCode(state, code) {
        state.quoteCurrencyCode = code;
    },
    updatePriceConversion(state, data) {
        state.priceConversion = data;
    },
    updateQuotationFlag(state, data) {
        state.quotationFlag = data;
    },
    updateSearchKeyWord(state, data) {
        state.searchKeyWord = data;
    }
}

const actions = {
    updateQuoteCurrencyCode(context, code) {
        context.commit('updateQuoteCurrencyCode', code)
    },
    updatePriceConversion(context, data) {
        context.commit('updatePriceConversion', data)
    },
    updateQuotationFlag(context, data) {
        context.commit('updateQuotationFlag', data)
    },
    updateSearchKeyWord(context, data) {
        context.commit('updateSearchKeyWord', data)
    },

}

const store = new Vuex.Store({
    state,
    getters,
    mutations,
    actions
})
export default store