import VueI18n from 'vue-i18n';
import Vue from 'vue'
import {
  enContent
} from './assets/js/en';
import {
  zhContent
} from './assets/js/zh';
import {
  jaContent
} from './assets/js/ja';
import {
  koContent
} from './assets/js/ko';
Vue.use(VueI18n);

// 国际化
const messages = {
  ZH: zhContent,
  JA: jaContent,
  KO: koContent,
  EN: enContent
}
const i18n = new VueI18n({
  locale: 'EN', // 语言标识
  messages
})
export default i18n