import axios from 'axios';
import { Toast } from 'mint-ui';
import { host } from '../config/index'
const instance = axios.create()
instance.defaults.baseURL = host;
instance.interceptors.response.use((response) => {
  if (response.data.resultStatus) {
    return response.data
  } else {
    // let errorMessage = response.data.errorMessage
    // Toast({
    //   message: errorMessage,
    //   position: 'middle',
    //   duration: 1500
    // })    
  }
}, (error) => {
  Toast({
    message: error,
    position: 'middle',
    duration: 1500
  })
})
export default instance