const koContent = {
  '行情热搜': '시세',
  '交易平台': '거래소',
  '没有更多了': '더 없어',
  '资讯公告': '정보',
  '全球总市值': "글로벌 시세",
  'BTC比特币': 'BTC比特币',
  'ETH以太坊': 'ETH以太坊',
  '资讯': '정보',
  '快讯': '뉴스',
  '热搜榜': '인기코인 검색',
  '市值榜': '시가총액',
  '灰度基金': '그레이 스케일 펀드',
  '币种': '통화',
  '持币数量/价值': '보유 수/값',
  '1天变化/7天变化': '1 일 변경 / 7 일 변경',
  '合约榜': '계약 차트',
  '2020减半': 'DeFi' || '2020 절반',
  '名称': '명칭',
  '热度': '열도',
  '流通市值': '유통 시가총액',
  '币种/流通市值': '화폐 종류 / 유통 시가',
  '全球指数': '글로벌 지수',
    '热度': '열도',
  '法币价格': '법정화폐가격',
  '法币价格': '법정화폐가격',
  '今日涨幅': '전일대비',
  '换手率': '회전율',
  '24h成交额': '거래대금 (24h)',
  '24h涨幅': '수익(24h)',
  '币种': '화폐 종류',
  '最低价': '최저 가',
  '平台': '플랫폼',
  '24H涨幅': '수익(24h)',
  '24H额': '24H이마',
  '24H量': '24H',
  '24H低': '24H낮다',
  '24H高': '24H높이',
  '24h成交量': '거래량 (24h)',
  '流动性': '유동성',
  '上线时间': '상선 시간',
  '持有资金量': '자금량',
  '交易对': '마켓거래량',
  '交易所': '거래소',
  '资讯': '정보',
  '快讯': '뉴스',
  '查看更多': '더 보기',
  '全部资讯': '모든 정보',
  '交易行情': '시장 동향',
  '交易k线': '무역 K 라인',
  '基本信息': '프로젝트 정보',
  '历史数据': '역사적 데이터',
  '直达': '직행',
  '占比': '비중을 차지하다',
  '更新时间': '업데이트 시간',
  '全网': '모든 네트워크',
  '流通率': '유통 률',
  '全球总市值占比': '글로벌 시가총액 점유율',
  '历史数据': '역사적 데이터',
  '日期': '날짜',
  '开盘价': '개장 가격',
  '最高价': '최고가',
  '最低价': '최저가',
  '24H成交额': '24H 거래액',
  '持有':'보유',
  '收盘价': '파장 가격',
  '市值': '시가총액',
  '开始日期': '시작 날짜',
  '结束日期': '마감 날짜',
  '发行时间': '발행 시간',
  '最大供应量': '최대 공급량',
  '总供应量': '총 공급량',
  '上架交易所': '상장된 거래소 숫자',
  '核心算法': '알고리즘',
  '奖励机制': '합의 매커니즘',
  '概念分类': '개념 분류',
  '相关链接': '링크',
  '官网地址': '웹사이트',
  '白皮书': '백서',
  '区块站': '익스플로러',
  '社交网站': '소셜 사이트',
  '发展历程和路线': '발전 과정과 노선',
  '简介': '소개',
  '搜索资讯': '정보 검색',
  '全部资讯': '모든 정보',
  '访问量': '방문자수',
  '全球综合排名榜': '세계 종합 순위',
  '24H涨跌幅': '24H 등락폭',
  '成立时间': '성립 시간',
  '资产实力': '자산 실력',
  '人气指数': '인기 지수',
  '注册地区': '등록 장소',
  '币种数量': '화폐 종류 수량',
  'KYC认证': 'KYC 인증',
  '应用下载': '다운로드',
  '手续费用': '수수료',
  '交易支持': '교역 지원',
  '阶段涨幅': '무대 증가',
  '价格': '가격',
  '最新价': '최신 가격',
  '平台价': '플랫폼 가격',
  '币种搜索':'통화 검색',
  'nav': {
    '1日': '1일',
    '1周': '1주',
    '1月': '1개월',
    '6月': '6개월',
    '1年': '1년',
    '3年': '3년',
    '全部': '모두',
    '今日': '오늘',
    '近7天': '거의 7 일',
    '近30天': '거의 30 일',
    '近3个月': '거의 3 개월',
    '近6个月': '거의 6 개월',
    '近一年': '거의 1 년',
    '所有': '모두',
    '过去7天': '지난 7일',
    '过去30天': '지난 30일',
    '过去12个月': '지난 12개월'
  },
   '搜索你想要的货币资讯': '정보 검색',
   '搜索你想要的交易所信息': '거래소 정보 검색',
   '24H成交量': '거래량 (24h)',
   '24H成交额': '거래대금 (24h)',
   '暂无数据': '일시 데이터 없음',
   '市场行情': '시장 시세',
   '行情': '시세',
   '发行日期': '발행 일자',
   '市值总额': '시가 총액',
   '时间': '시간',
   '官网备用地址': '홈 페이지 예비 주소',
   '是':'네.',
   '否':'여부'
}
export {
  koContent
}
