<template>
  <div id="app">
   <router-view />
  </div>
</template>

<script>
export default {
  name: 'app',
}
</script>

<style>
body,p {
  margin: 0px;
}
html,body,#app{
  height: 100%;
  width: 100%;
}
</style>
