const enContent = {
    '行情热搜': 'Tokens',
    '没有更多了': 'No more',
    '交易平台': 'Exchanges',
    '资讯公告': 'Media',
    '全球总市值': "Global market capitalization",
    'BTC比特币': 'BTC比特币',
    'ETH以太坊': 'ETH以太坊',
    '资讯': 'Media',
    '快讯': 'News',
    '热搜榜': 'Hot search',
    '市值榜': 'Market Cap',
    '灰度基金': 'Grayscale Fund',
    '币种': 'Currency',
    '持币数量/价值': 'Position/Value',
    '1天变化/7天变化': '1 Day / 7 Days Change',
    '合约榜': 'Futures',
    '2020减半': 'DeFi' || '2020 halving',
    '名称': 'Name',
    '热度': 'Hot',
    '流通市值': 'Market Cap',
    '法币价格': 'Price',
    '法币价格': 'Price',
    '24H涨幅': 'Change(24h)',
    '币种/流通市值': 'Currency/Market Cap',
    '全球指数': 'Global indicators',
    '换手率': 'Turnover Rate',
    '热度': 'Hot',
    '今日涨幅': 'Change(24h)',
    '24h成交额': 'Volume(24h)',
    '24h涨幅': 'Change(24h)',
    '24H涨幅': 'Chg.(24h)',
    '24H额': '24H forehead',
    '24H量': '24H quantity',
    '24H低': '24H low',
    '24H高': '24H high',
    '24h成交量': 'Base Vol (24h)',
    '持有资金量': 'Asset Holdings',
    '流动性': 'Mobility',
    '上线时间': 'Launched',
    '交易对': 'Pairs',
    '交易所': 'Exchanges',
    '资讯': 'Media',
    '快讯': 'News',
    '查看更多': 'To view more',
    '全部资讯': 'All the information',
    '交易行情': 'Line Chart',
    '交易k线': 'Candlestick Chart',
    '基本信息': 'Project Intro',
    '历史数据': 'historical data',
    '直达': 'nonstop',
    '占比': 'make up',
    '更新时间': 'UpdateTime',
    '全网': 'full web',
    '流通率': 'Circulating Rate',
    '全球总市值占比': 'Proportion of Global Market Cap',
    '历史数据': 'historical data',
    '日期': 'date',
    '开盘价': 'opening price',
    '最高价': 'Highest Price',
    '最低价': 'minimum price',
    '收盘价': 'closing price',
    '市值': 'Market Cap',
    '开始日期': 'Start Date',
    '结束日期': 'End Date',
    '发行时间': 'Issue Time',
    '最大供应量': 'Max Supply',
    '总供应量': 'Total Supply',
    '上架交易所': 'Online exchange number',
    '核心算法': 'Algorithm',
    '奖励机制': 'Consensus Mechanism',
    '概念分类': 'concept classification',
    '相关链接': 'Link',
    '官网地址': 'Website',
    '白皮书': 'Whtiepaper',
    '区块站': 'Explore',
    '社交网站': 'Social networking sites',
    '发展历程和路线': 'Development process and route',
    '简介': 'Intro',
    '搜索资讯': 'Search for information',
    '全部资讯': 'All the information',
    '访问量': 'Visits',
    '全球综合排名榜': 'Global rankings',
    '24H涨跌幅': '24H Ups and downs',
    '24H成交额': '24H turnover',
    '持有': 'Holding',
    '成立时间': 'Founded Time',
    '资产实力': 'Asset strength',
    '人气指数': 'popularity index',
    '注册地区': 'Overall Rank',
    '币种数量': 'Currency quantity',
    'KYC认证': 'KYC Authentication',
    '应用下载': 'Application download',
    '手续费用': 'Procedure cost',
    '交易支持': 'Trading Support',
    '阶段涨幅': 'Periodic Changes',
    '价格': 'Price',
    '最新价': 'Latest prices',
    '平台价': 'The platform price',
    '币种搜索':'Currency Search',
    '币种':'Currency',
    '最低价':'Minimum Price',
    // 
    '平台':'Platform',
    'nav': {
        '1日': '1d',
        '1周': '1w',
        '1月': '1mo',
        '6月': '6mo',
        '1年': '1y',
        '3年': '3y',
        '全部': 'All',
        '今日': 'Today',
        '近7天': 'Prev 7d',
        '近30天': 'Prev 30d',
        '近3个月': 'Prev 3mo',
        '近6个月': 'Prev 6mo',
        '近一年': 'Prev 1y',
        '所有': 'All',
        '过去7天': 'Past 7 days',
        '过去30天': 'Past 30 days',
        '过去12个月': 'Last 12 months'
    },
    '搜索你想要的货币资讯': 'Search for information',
    '搜索你想要的交易所信息': 'Search for exchange information',
    '24H成交量': 'Base Vol (24h)',
    '24H成交额': 'Volume(24h)',
    '暂无数据': 'No data',
    '市场行情': 'Market Quotation',
    '行情': 'Market',
    '市值总额': 'Market Capitalization',
    '发行日期': 'Issue date',
    '时间': 'Time',
    '官网备用地址': 'Alternate address of official website',
    '是':'YES',
    '否':'NO'
}
export {
    enContent
}
